<template>
  <ModuleSubPage fullWidth title="NAVISION.IMPORTS.LIST.TITLE">
    <BaseHasPermission :permissionNames="['unitop.import.read']">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-spacer />

            <v-col cols="3">
              <BaseTableSearchField
                v-model="filter.search"
                @triggerSearch="retrieveImportData"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <ImportTable
            :imports="importData"
            :loading="isLoading"
            :options.sync="options"
            :server-items-length="importMeta.total"
            @reload="retrieveImportData"
            :headers="headers"
          />
        </v-card-text>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import ImportTable from "@views/Restricted/Navision/Imports/List/partials/ImportTable.vue";
import i18n from "@/i18n";

import { useImportDataOutput } from "@use/useImportDataOutput";
export default {
  name: "ListImportsSeminar",

  setup() {
    const { importData, importMeta, isLoading, retrieveImportData, options, filter } =
      useImportDataOutput();

    const headers = [
      {
        text: i18n.t("NAVISION.IMPORTS.LIST.TABLE.HEADER_SEMINAR.TITLE"),
        value: "objectData.Beschreibung",
        sortable: true,
      },

      {
        text: i18n.t("NAVISION.IMPORTS.LIST.TABLE.HEADER_SEMINAR.BWS_NUMBER"),
        value: "objectData.Event_No_IGBCE",
        sortable: false,
      },
    ];

    return {
      importData,
      importMeta,
      isLoading,
      retrieveImportData,
      options,
      filter,
      headers,
    };
  },

  components: {
    ModuleSubPage,
    ImportTable,
  },
};
</script>
